<template>
  <v-container class="md:pt-3">
    <validation-observer ref="observer" v-slot="{ invalid }">
      <v-form ref="form">
        <!-- Container  -->
        <v-row>
          <v-col cols="12" md="4">
            <div class="md:px-4 sm:px-0">
              <h3 class="text-lg font-medium leading-6 text-gray-900">
                Información del comercio
              </h3>
              <p class="mt-1 text-sm text-gray-600">
                Esta informacion será mostrada a tus clientes al momento de
                realizar un pago.
              </p>
            </div>
          </v-col>

          <v-col cols="12" md="8">
            <v-row>
              <v-col cols="12" md="4">
                <v-avatar @click="triggerFileInput" class="profile" size="90">
                  <v-img :src="myMerchant.logo ||
                    'https://media.istockphoto.com/vectors/thumbnail-image-vector-graphic-vector-id1147544807?k=20&m=1147544807&s=612x612&w=0&h=pBhz1dkwsCMq37Udtp9sfxbjaMl27JUapoyYpQm0anc='
                    "></v-img>
                </v-avatar>
                <v-btn class="ml-2 btn" @click="triggerFileInput" elevation="1" icon>
                  <v-icon>mdi-image-plus</v-icon>
                </v-btn>
                <v-file-input ref="file" @change="onFileChange" style="display: none" id="logoInput" :rules="logoRules"
                              accept="image/png, image/jpeg, image/bmp" placeholder="Pick an avatar"
                              prepend-icon="mdi-camera"
                              label="Avatar"></v-file-input>
              </v-col>

              <v-col cols="12" md="8">
                <validation-provider v-slot="{ errors }" name="companyName" rules="required">
                  <label for="companyName" class="text-sm">Nombre del comercio</label>
                  <br/>
                  <input @change="enableEditing()" v-model="myMerchant.companyName" type="text" id="companyName"
                         :error-messages="errors" placeholder="Yoyo" :class="inputClasses"/>
                  <span style="color: red">{{ errors[0] }}</span>
                  <span class="waiting-for-review text-sm" v-if="
                    pendingUpdateRequest &&
                    pendingUpdateRequest.changes &&
                    pendingUpdateRequest.changes['companyName']
                  ">{{ waitingForReviewMsg }}</span>
                </validation-provider>
              </v-col>
            </v-row>
            <span class="p-0 waiting-for-review text-sm" v-if="
              pendingUpdateRequest &&
              pendingUpdateRequest.changes &&
              pendingUpdateRequest.changes['logo']
            ">{{ "Reviewing..." }}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="4">
            <div class="md:px-4 sm:px-0">
              <h3 class="text-lg font-medium leading-6 text-gray-900">
                Información Bancaria
              </h3>
              <p class="mt-1 text-sm text-gray-600">
                Esta informacion es privada, no es compartida con nadie. Tus
                fondos en Yoyo seran depositados en esta cuenta una vez haya
                sido validada.
              </p>
            </div>
          </v-col>

          <v-col cols="12" md="8">
            <v-row>
              <v-col cols="12" md="12">
                <v-card outline elevation="1">
                  <v-card-title>{{ myMerchant.bankAccounts[0].holder }} -
                    {{ myMerchant.bankAccounts[0].number }}
                  </v-card-title>
                  <v-card-subtitle>{{
                      banks.find(
                          (x) => x.value === myMerchant.bankAccounts[0].bank
                      )
                          ? banks.find(
                              (x) => x.value === myMerchant.bankAccounts[0].bank
                          ).text
                          : myMerchant.bankAccounts[0].bank
                    }}

                    {{
                      bankAccountTypes.find(
                          (x) => x.value === myMerchant.bankAccounts[0].type
                      ) &&
                      bankAccountTypes.find(
                          (x) => x.value === myMerchant.bankAccounts[0].type
                      ).value !== ""
                          ? "| " +
                          bankAccountTypes.find(
                              (x) => x.value === myMerchant.bankAccounts[0].type
                          ).text
                          : ""
                    }}
                  </v-card-subtitle>

                  <v-divider class="mx-4"></v-divider>
                  <v-card-actions class="justify-end">
                    <span class="p-1 waiting-for-review text-center text-sm" v-if="
                      pendingUpdateRequest &&
                      pendingUpdateRequest.changes &&
                      pendingUpdateRequest.changes['bankAccounts']
                    ">{{ waitingForReviewMsg }}
                    </span>
                    <v-btn v-if="toggleBankAccountInputs" @click="toggleBankAccountInputs = false" color="red darken-1"
                           text>
                      Cancelar
                    </v-btn>
                    <v-btn v-if="!toggleBankAccountInputs" @click="toggleBankAccountInputs = true" color="blue darken-1"
                           text>
                      Editar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>

              <v-col v-if="toggleBankAccountInputs" cols="7">
                <validation-provider v-slot="{ errors }" name="Banco" rules="required">
                  <label for="Banco" class="text-sm">Banco</label>
                  <br/>
                  <select @change="enableEditing()" :class="inputClasses" v-model="myMerchant.bankAccounts[0].bank"
                          :error-messages="errors" name="Banco" required>
                    <option v-if="!myMerchant.bankAccounts[0].bank" disabled selected value>
                      -- Selecciona un banco --
                    </option>
                    <option v-for="i in banks" :key="i.value" :value="i.value">
                      {{ i.text }}
                    </option>
                  </select>
                  <span style="color: red">{{ errors[0] }}</span>
                </validation-provider>
              </v-col>

              <v-col v-if="toggleBankAccountInputs" cols="12" md="5">
                <validation-provider v-slot="{ errors }" name="Tipo de Cuenta" rules="required">
                  <label for="Tipo de Cuenta" class="text-sm">Tipo de Cuenta</label>
                  <br/>
                  <select @change="enableEditing()" :class="inputClasses" v-model="myMerchant.bankAccounts[0].type"
                          :error-messages="errors" name="Tipo de Cuenta">
                    <option v-for="i in bankAccountTypes" :key="i.value" :value="i.value">
                      {{ i.text }}
                    </option>
                  </select>
                  <span style="color: red">{{ errors[0] }}</span>
                </validation-provider>
              </v-col>

              <v-col v-if="toggleBankAccountInputs" cols="12" md="6">
                <validation-provider v-slot="{ errors }" name="Numero De Cuenta" rules="required">
                  <label for="Numero De Cuenta" class="text-sm">Numero De Cuenta</label>
                  <br/>
                  <input @change="enableEditing()" v-model="myMerchant.bankAccounts[0].number" type="tel"
                         id="Numero De Cuenta" :error-messages="errors" placeholder="0000000000" :class="inputClasses"/>
                  <span style="color: red">{{ errors[0] }}</span>
                  <span style="color: orange">{{ errors[0] }}</span>
                </validation-provider>
              </v-col>

              <v-col v-if="toggleBankAccountInputs" cols="12" md="6">
                <validation-provider v-slot="{ errors }" name="Titular de Cuenta" rules="required">
                  <label for="Titular de Cuenta" class="text-sm">Titular de Cuenta</label>
                  <br/>
                  <input @change="enableEditing()" v-model="myMerchant.bankAccounts[0].holder" type="text"
                         id="Titular de Cuenta" :error-messages="errors" placeholder="John Doe" :class="inputClasses"/>
                  <span style="color: red">{{ errors[0] }}</span>
                </validation-provider>
              </v-col>
              <div v-if="toggleBankAccountInputs">
                <span class="waiting-for-review text-sm" v-if="
                  pendingUpdateRequest &&
                  pendingUpdateRequest.changes &&
                  pendingUpdateRequest.changes['bankAccounts']
                ">{{ waitingForReviewMsg }}
                </span>
              </div>
            </v-row>
          </v-col>
        </v-row>
        <div>
          <br/>
          <v-divider class="mb-5"></v-divider>

          <v-row>
            <v-col cols="12" md="4">
              <div class="md:px-4 sm:px-0">
                <h3 class="text-lg font-medium leading-6 text-gray-900">
                  Representante
                </h3>
                <p class="mt-1 text-sm text-gray-600">
                  Informacion basica y de contacto del representante del
                  comercio. El nombre del representante será presentado a sus
                  clientes.
                </p>
              </div>
            </v-col>

            <v-col cols="12" md="8">
              <v-row>
                <v-col cols="12" md="12">
                  <validation-provider v-slot="{ errors }" name="name" rules="required">
                    <label for="name" class="text-sm">Nombre</label>
                    <br/>
                    <input @change="enableEditing()" v-model="myMerchant.name" type="text" id="name"
                           :error-messages="errors" placeholder="John Doe" :class="inputClasses"/>
                    <span style="color: red">{{ errors[0] }}</span>
                    <span class="waiting-for-review text-sm" v-if="
                      pendingUpdateRequest &&
                      pendingUpdateRequest.changes &&
                      pendingUpdateRequest.changes['name']
                    ">{{ waitingForReviewMsg }}
                    </span>
                  </validation-provider>
                </v-col>

                <v-col cols="12" md="6">
                  <validation-provider v-slot="{ errors }" name="email" rules="required|email">
                    <label for="email" class="text-sm">Email</label>
                    <br/>
                    <input @change="enableEditing()" v-model="myMerchant.email" type="email" id="email"
                           :error-messages="errors" placeholder="comercio@yoyo.do" :class="inputClasses"/>
                    <span style="color: red">{{ errors[0] }}</span>
                    <span class="waiting-for-review text-sm" v-if="
                      pendingUpdateRequest &&
                      pendingUpdateRequest.changes &&
                      pendingUpdateRequest.changes['email']
                    ">{{ waitingForReviewMsg }}
                    </span>
                  </validation-provider>
                </v-col>

                <v-col cols="12" md="6">
                  <validation-provider v-slot="{ errors }" name="phone" rules="required">
                    <label for="phone" class="text-sm">Telefono</label>
                    <br/>
                    <vue-tel-input ref="phoneInput" @input="onPhoneChange($event)" @validate="onPhoneValidate($event)" id="phone" required class="my-3" style="padding-top: 3px" error-messages="errors" v-model="myMerchant.phone" defaultCountry="do"></vue-tel-input>
                    <span style="color: red">{{ errors[0] }}</span>
                    <span v-if="errors.length === 0" style="color: red">{{ phoneValidationError }}</span>
                    <span class="waiting-for-review text-sm" v-if="
                      pendingUpdateRequest &&
                      pendingUpdateRequest.changes &&
                      pendingUpdateRequest.changes['phone']
                    ">{{ waitingForReviewMsg }}
                    </span>
                  </validation-provider>
                </v-col>

                <v-col cols="12" md="6">
                  <validation-provider v-slot="{ errors }" name="documentType">
                    <label for="documentType" class="text-sm">Tipo de Documento</label>
                    <br/>
                    <select @change="enableEditing()" :class="inputClasses" v-model="myMerchant.document.type"
                            :error-messages="errors" name="documentType" id="documentType">
                      <option v-for="i in documentTypes" :key="i.value" :value="i.value">
                        {{ i.text }}
                      </option>
                    </select>
                    <span style="color: red">{{ errors[0] }}</span>
                  </validation-provider>
                </v-col>

                <v-col cols="12" md="6">
                  <validation-provider v-slot="{ errors }" name="documentNumber" rules="required">
                    <label for="documentNumber" class="text-sm">Documento</label>
                    <br/>
                    <input @change="enableEditing()" v-model="myMerchant.document.number" type="tel" id="documentNumber"
                           :error-messages="errors" placeholder="###-#######-#" :class="inputClasses"/>
                    <span style="color: red">{{ errors[0] }}</span>
                  </validation-provider>

                  <span class="waiting-for-review text-sm" v-if="
                    pendingUpdateRequest &&
                    pendingUpdateRequest.changes &&
                    pendingUpdateRequest.changes['document']
                  ">{{ waitingForReviewMsg }}
                  </span>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <br/>
          <v-divider class="mb-5"></v-divider>
        </div>

        <v-row>
          <v-col cols="12" md="4">
            <div class="md:px-4 sm:px-0">
              <h3 class="text-lg font-medium leading-6 text-gray-900">
                Dirección
              </h3>
              <p class="mt-1 text-sm text-gray-600">
                Por favor, proporciona la información de tu dirección. Esta información incluye la provincia, el
                municipio, el sector, el barrio y la calle. Asegúrate de que la dirección sea precisa para evitar
                cualquier inconveniente.
              </p>
            </div>
          </v-col>

          <!-- Address imput -->
          <v-col cols="12" md="8">
            <v-row>
              <!-- Provincia dropdown -->
              <v-col cols="12" md="6">
                <validation-provider v-slot="{ errors }" name="Provincia" rules="required">
                  <label for="province" class="text-sm">Provincia</label>
                  <select ref="provinceSelect" @change="onProvinceSet" v-model="myMerchant.address.province"
                          type="input" id="province"
                          :error-messages="errors" :class="inputClasses">
                    <option v-for="i in provinces" :key="i.identifier" :value="i.code">{{ i.name }}</option>
                  </select>
                  <span style="color: red">{{ errors[0] }}</span>
                  <span class="waiting-for-review text-sm" v-if="
                  !errors.length &&
                    pendingUpdateRequest &&
                    pendingUpdateRequest.changes &&
                    pendingUpdateRequest.changes['address'] &&
                    pendingUpdateRequest.changes['address']['province']
                  ">{{ waitingForReviewMsg }}
                  </span>
                </validation-provider>
              </v-col>
              <!-- Provincia dropdown end-->

              <!-- Municipio Dropdown -->
              <v-col cols="12" md="6">
                <validation-provider v-slot="{ errors }" name="Municipio" rules="required">
                  <label for="town" class="text-sm">Municipio</label>
                  <select @change="onMunicipalitySet" v-model="myMerchant.address.municipality" type="input"
                          id="town"
                          :error-messages="errors" :class="inputClasses">
                    <option v-for="i in municipalities" :key="i.identifier" :value="i.code">{{ i.name }}</option>
                  </select>
                  <span style="color: red">{{ errors[0] }}</span>
                  <span class="waiting-for-review text-sm" v-if="
                    !errors.length &&
                    pendingUpdateRequest &&
                    pendingUpdateRequest.changes &&
                    pendingUpdateRequest.changes['address'] &&
                    pendingUpdateRequest.changes['address']['municipality']
                  ">{{ waitingForReviewMsg }}
                  </span>
                </validation-provider>
              </v-col>
              <!-- Municipio Dropdown end-->

              <!-- City Dropdown -->
              <v-col cols="12" md="6">
                <validation-provider v-slot="{ errors }" name="Sector" rules="required">
                  <label for="city" class="text-sm">Sector</label>
                  <select @change="onSectorSet" v-model="myMerchant.address.sector" type="input" id="city"
                          :error-messages="errors" :class="inputClasses">
                    <option v-for="i in sectors" :key="i.identifier" :value="i.code">{{ i.name }}</option>
                  </select>
                  <span style="color: red">{{ errors[0] }}</span>
                  <span class="waiting-for-review text-sm" v-if="
                    !errors.length &&
                    pendingUpdateRequest &&
                    pendingUpdateRequest.changes &&
                    pendingUpdateRequest.changes['address'] &&
                    pendingUpdateRequest.changes['address']['sector']
                  ">{{ waitingForReviewMsg }}
                  </span>
                </validation-provider>
              </v-col>
              <!-- City Dropdown end-->

              <!-- Barrio field -->
              <v-col cols="12" md="6">
                <validation-provider v-slot="{ errors }" name="Barrio" rules="required">
                  <label for="neighborhood" class="text-sm">Barrio</label>
                  <select @change="onNeighborhoodSet()" v-model="myMerchant.address.neighborhood" type="input"
                          id="neighborhood"
                          :error-messages="errors" :class="inputClasses">
                    <option v-for="i in neighborhoods" :key="i.identifier" :value="i.code">{{ i.name }}</option>
                  </select>
                  <span style="color: red">{{ errors[0] }}</span>
                  <span class="waiting-for-review text-sm" v-if="
                    !errors.length &&
                    pendingUpdateRequest &&
                    pendingUpdateRequest.changes &&
                    pendingUpdateRequest.changes['address'] &&
                    pendingUpdateRequest.changes['address']['neighborhood']
                  ">{{ waitingForReviewMsg }}
                  </span>
                </validation-provider>
              </v-col>

              <!-- Barrio field end-->
              <v-col cols="12" md="12">
                <validation-provider v-slot="{ errors }" name="Dirección" rules="required">
                  <label for="address" class="text-sm">Dirección</label>
                  <input @change="enableEditing()" v-model="myMerchant.address.street" type="text" id="address"
                         :error-messages="errors" placeholder="Av. Pedro Henríquez Ureña esq. Av. Leopoldo Navarro"
                         :class="inputClasses"/>
                  <span style="color: red">{{ errors[0] }}</span>
                  <span class="waiting-for-review text-sm" v-if="
                    !errors.length &&
                    pendingUpdateRequest &&
                    pendingUpdateRequest.changes &&
                    pendingUpdateRequest.changes['address'] &&
                    pendingUpdateRequest.changes['address']['street']
                  ">{{ waitingForReviewMsg }}
                  </span>
                </validation-provider>
              </v-col>


            </v-row>
          </v-col>
        </v-row>

        <br/>
        <v-divider class="mb-5"></v-divider>


        <div class="error-message">
          <v-alert v-if="error" class="mb-0" type="error">
            {{ error }}
          </v-alert>
        </div>

        <v-row class="">
          <v-col cols="12" md="12" align="right">
            <v-btn :disabled="!myMerchant.address ||
            !myMerchant.address.province ||
!myMerchant.address.municipality ||
!myMerchant.address.sector ||
!myMerchant.address.neighborhood ||
!myMerchant.address.street" color="success" class="mr-4 mt-4"
                   @click="requestUpdate">
              Actualizar Informacion
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </validation-observer>
    <alert ref="alert"/>

  </v-container>
</template>
<script>
import { renewSession } from "@/utils";
import Alert from "@/components/Alert.vue";
import dot from "dot-object";
import Vue from "vue";
import { digits, email, max, regex, required } from "vee-validate/dist/rules";
import { extend, setInteractionMode, ValidationObserver, ValidationProvider, } from "vee-validate";
import { getMunicipalities, getNeighborhoods, getProvinces, getSectors } from "@/helpers/territorial-division";
import { ErrorCode } from "@/enums";
import { AUTH_HEADER } from "@/config/constants";

extend("digits", {
  ...digits,
  message: "{_field_} needs to be {length} digits. ({_value_})",
});

extend("required", {
  ...required,
  message: "{_field_} no puede estar vacio",
});

extend("max", {
  ...max,
  message: "{_field_} may not be greater than {length} characters",
});

extend("regex", {
  ...regex,
  message: "{_field_} {_value_} does not match {regex}",
});

extend("email", {
  ...email,
  message: "Email must be valid",
});

setInteractionMode("eager");

export default Vue.extend({
  name: "Settings",
  data: () => ({
    phoneValidationError: '',
    enabled: false,
    toggleBankAccountInputs: false,
    pendingUpdateRequest: {},
    inputClasses:
          "shadow appearance-none border rounded w-full py-3 px-2 text-gray-700 mt-3 leading-tight focus:outline-none focus:shadow-outline",
    logoRules: [
      (value) =>
          !value ||
          value.size < 3000000 ||
          "Avatar size should be less than 3 MB!",
    ],
    nameRules: [
      (v) => !!v || "Name is required",
      (v) => (v && v.length <= 30) || "Name must be less than 10 characters",
    ],
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    error: "",
    documentTypes: [
      {value: "country_issued", text: "Cedula"},
      {value: "passport", text: "Pasaporte"},
      {value: "business", text: "RNC"},
    ],
    bankAccountTypes: [
      {value: "checking", text: "Cuenta Corriente"},
      {value: "savings", text: "Cuenta De Ahorros"},
      {value: "", text: ""}, //unset type
    ],
    provinces: [],
    municipalities: [],
    sectors: [],
    neighborhoods: [],
    selectedCountry: "DO",
    countries: [],
    banks: [],
    waitingForReviewMsg:
        "Este cambio esta siendo revisado por la administracion de Yoyo.",
    myMerchant: {
      code: "",
      name: "",
      phone: "",
      phoneObj: {},
      email: "",
      companyName: "",
      document: {
        type: "country_issued" | "passport" | "business",
        number: "",
      },
      bankAccounts: [{bank: null, number: "", type: "", holder: ""}],
      logo: "",
      qr: "",
      username: "",
      address: {
        province: "",
        municipality: "",
        sector: "",
        neighborhood: "",
        street: ""
      }
    },
  }),
  props: {
    merchant: {},
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    Alert,
  },
  watch: {
    merchant() {
      if (!this.pendingUpdateRequest)
        if (this.merchant._id)
          this.myMerchant = {
            ...this.merchant,
            document: {
              type: this.merchant.document.type,
              number: this.merchant.document.number,
            },
            bankAccounts: [
              {
                type: this.merchant.bankAccounts[0].type || "",
                number: this.merchant.bankAccounts[0].number || "",
                holder: this.merchant.bankAccounts[0].holder || "",
                bank: this.setBank(this.merchant.bankAccounts[0].bank) || "",
              },
            ],
            logo: this.merchant.logo,
            address: {
              province: this.merchant.address.province || "",
              municipality: this.merchant.address.municipality || "",
              sector: this.merchant.address.sector || "",
              neighborhood: this.merchant.address.neighborhood || "",
              street: this.merchant.address.street || ""
            }
          };
    },
    pendingUpdateRequest() {
      this.myMerchant = this.pendingUpdateRequest
          ? {
            ...this.pendingUpdateRequest.preview,
            document: {
              type: this.pendingUpdateRequest.preview.document.type,
              number: this.pendingUpdateRequest.preview.document.number,
            },
            bankAccounts: [
              {
                type:
                    this.pendingUpdateRequest.preview.bankAccounts[0].type || "",
                number:
                    this.pendingUpdateRequest.preview.bankAccounts[0].number ||
                    "",
                holder:
                    this.pendingUpdateRequest.preview.bankAccounts[0].holder ||
                    "",
                bank:
                    this.setBank(
                        this.pendingUpdateRequest.preview.bankAccounts[0].bank
                    ) || "",
              },
            ],
            logo: this.pendingUpdateRequest.preview.logo,
            address: {
              province: this.pendingUpdateRequest.preview.address?.province || "",
              municipality: this.pendingUpdateRequest.preview.address?.municipality || "",
              sector: this.pendingUpdateRequest.preview.address?.sector || "",
              neighborhood: this.pendingUpdateRequest.preview.address?.neighborhood || "",
              street: this.pendingUpdateRequest.preview.address?.street || ""
            }
          }
          : {
            ...(this.merchant._id
                ? {
                  ...this.merchant,
                  document: {
                    type: this.merchant.document.type,
                    number: this.merchant.document.number,
                  },
                  bankAccounts: [
                    {
                      type:
                          (this.merchant.bankAccounts &&
                              this.merchant.bankAccounts[0] &&
                              this.merchant.bankAccounts[0].type) ||
                          "",
                      number: this.merchant.bankAccounts[0].number || "",
                      holder: this.merchant.bankAccounts[0].holder || "",
                      bank:
                          this.setBank(this.merchant.bankAccounts[0].bank) || "",
                    },
                  ],
                  logo: this.merchant.logo,
                  address: {
                    province: this.merchant.address.province || "",
                    municipality: this.merchant.address.municipality || "",
                    sector: this.merchant.address.sector || "",
                    neighborhood: this.merchant.address.neighborhood || "",
                    street: this.merchant.address.street || ""
                  }
                }
                : {}),
          };
    },
  },
  async mounted() {
    this.getBanks();
    this.getProvinces();
    await this.getPendingUpdate();

    // If the user has not added an address for its merchant, show and alert and
    // focus on the address fields.
    if (!this.pendingUpdateRequest.preview.address) {
      await this.$refs.alert.showAlert({
        title: "Aviso",
        text: "Hemos realizado cambios en nuestros requisitos. Para garantizar un servicio óptimo," +
            " necesitamos que actualices la dirección de tu comercio. Por favor, completa los campos" +
            " correspondientes para continuar. Agradecemos tu colaboración.",
        buttons: [{ text: "Entendido" }],
      });

      // Simulate click on the province input.
      this.$refs.provinceSelect.focus();
      this.$refs.provinceSelect.click();
    }
  },
  methods: {
    async getProvinces() {
      this.provinces = await getProvinces();
    },
    async getMunicipalities(province) {
      this.municipalities = await getMunicipalities(province);
    },
    async getSectors(province, municipality) {
      this.sectors = await getSectors(province, municipality);
    },
    async getNeighborhoods(province, municipality, sector) {
      this.neighborhoods = await getNeighborhoods(province, municipality, sector);
    },
    onProvinceSet(e) {
      const province = e.target.value;

      // Clean address
      this.myMerchant.address.municipality =
          this.myMerchant.address.sector =
              this.myMerchant.address.neighborhood =
                  this.myMerchant.address.street ="";

      this.getMunicipalities(province);
    },
    onMunicipalitySet(e) {
      const province = this.myMerchant.address.province;
      const municipality = e.target.value;

      // Clean address
      this.myMerchant.address.sector =
          this.myMerchant.address.neighborhood =
              this.myMerchant.address.street = "";

      this.getSectors(province, municipality);
    },
    onSectorSet(e) {
      const province = this.myMerchant.address.province;
      const municipality = this.myMerchant.address.municipality;
      const sector = e.target.value;

      // Clean Address
      this.myMerchant.address.neighborhood =
          this.myMerchant.address.street ="";

      this.getNeighborhoods(province, municipality, sector);
    },
    onNeighborhoodSet() {
      // Clean Address
      this.myMerchant.address.street ="";
    },
    onFileChange() {
      const imagefile = document.querySelector("#logoInput");
      const [file] = imagefile.files;
      if (file) {
        this.myMerchant.logo = URL.createObjectURL(file);
        this.enabled = true;
      }
    },
    async getBanks() {
      this.error = "";
      const resp = await fetch(`${process.env.VUE_APP_API_URL}/misc/banks`, {
        headers: {
          "Content-Type": "application/json",
          [AUTH_HEADER]: process.env.VUE_APP_API_KEY,
        },
      });

      const {ok, data, errors, errorMessage} = await resp.json();
      if (ok) {
        this.banks = data.map((x) => ({value: x.code, text: x.bankName}));
        renewSession(resp);
      }
      if (!ok) {
        if (errorMessage) this.error = errorMessage;
        else if (errors) {
          this.error = errors[0].msg;
        } else {
          this.error = resp.statusText;
        }
      }
    },
    async getPendingUpdate() {
      this.error = "";
      const resp = await fetch(
          `${process.env.VUE_APP_API_URL}/merchants/pending-update`,
          {
            headers: {
              "Content-Type": "application/json",
              [AUTH_HEADER]: localStorage.getItem("session"),
            },
          }
      );

      const {ok, data, errors, errorMessage} = await resp.json();
      if (ok) {
        // if address field is present in the data,
        // We need to get the municipalities, sectors and neighborhoods.
        const address = data.preview.address;
        if (address) {
          this.getMunicipalities(address.province);
          this.getSectors(address.province, address.municipality);
          this.getNeighborhoods(address.province, address.municipality, address.sector);
        }

        this.pendingUpdateRequest = data;
        renewSession(resp);
      }
      if (!ok) {
        if (errorMessage) this.error = errorMessage;
        else if (errors) {
          this.error = errors[0].msg;
        } else {
          this.error = resp.statusText;
        }
      }
    },
    triggerFileInput() {
      this.$refs.file.$refs.input.click();
    },
    enableEditing() {
      this.enabled = true;
    },
    onPhoneValidate(phoneObj) {
      this.enableEditing();

      if (phoneObj.valid) {
        this.myMerchant.phoneObj = phoneObj;
      }
      this.phoneValidationError = phoneObj.valid ? "" : "Número de teléfono inválido";
    },
    onPhoneChange(_) {
      const phoneObj = this.$refs.phoneInput.phoneObj;
      if ( phoneObj && phoneObj.valid) {
        this.myMerchant.phoneObj = phoneObj;
        this.enableEditing();
      }
    },
    setBank(bankCode) {
      const bank = this.banks.find((x) => x.value == bankCode);
      return bank ? bank.value : null;
    },
    async requestUpdate() {
      const isValid = await this.$refs.observer.validate();
      if (isValid && this.myMerchant.bankAccounts[0].bank) {
        const agree = await this.$refs.alert.showAlert({
          title: "Actualizar",
          text: "Verificaste la informacion?",
          buttons: [
            {text: "No", color: "red", role: "cancel"},
            {text: "Si"},
          ],
        });

        if (!agree) return;

        var formData = new FormData();
        var imagefile = document.querySelector("#logoInput");
        const [file] = imagefile.files;
        if (file) formData.append("logo", file);

        const merchantInfo = {
          name: this.myMerchant.name,
          phone: this.myMerchant.phoneObj.number,
          phoneCountryISO: this.myMerchant.phoneObj.countryCode,
          email: this.myMerchant.email,
          companyName: this.myMerchant.companyName,
          document: {
            type: this.myMerchant.document.type,
            number: this.myMerchant.document.number,
          },
          address: {
            province: this.myMerchant.address.province,
            municipality: this.myMerchant.address.municipality,
            sector: this.myMerchant.address.sector,
            neighborhood: this.myMerchant.address.neighborhood,
            street: this.myMerchant.address.street,
          },
          //   logo: "",
        };

        const bankAccounts = {
          bankAccounts: [
            {
              type: this.myMerchant.bankAccounts[0].type,
              number: this.myMerchant.bankAccounts[0].number,
              holder: this.myMerchant.bankAccounts[0].holder,
              bank: this.myMerchant.bankAccounts[0].bank,
            },
          ],
        };
        const dotifiedBody = {
          ...dot.dot(merchantInfo),
          ...dot.dot(bankAccounts),
        };
        for (const key of Object.keys(dotifiedBody)) {
          formData.append(key, dotifiedBody[key]);
        }

        this.error = "";
        const urlParams = new URLSearchParams(window.location.search);
        const queryParams = new URLSearchParams({
          ...(urlParams.has("trigger") //this works to automatically trigger cashout after update.
              ? {trigger: urlParams.get("trigger")}
              : {}),
        });
        const resp = await fetch(
            `${process.env.VUE_APP_API_URL}/merchants/request-update/?` +
            queryParams,
            {
              method: "POST",
              headers: {
                [AUTH_HEADER]: localStorage.getItem("session"),
              },
              body: formData,
            }
        );

        const {ok, data, errors, errorMessage} = await resp.json();
        if (ok) {
          this.myMerchant = data.preview;
          this.pendingUpdateRequest = data;
          this.enabled = !this.enabled;
          // this.$emit("update:merchant", data);

          this.toggleBankAccountInputs = false;
          if (this.pendingUpdateRequest.changes) {
            this.$refs.alert.showAlert({
              title: "Aviso!",
              text: "Tus actualizaciones estarán siendo revisadas por un representante de Yoyo, una vez hecho, tus cambios serán aplicados y te notificaremos.",
              buttons: [{ text: "Ok" }],
            });
          }

          // Add a custom error message for when the user gets sent here from the mobile app.
          const errorCode = urlParams.get("errorCode")
          if (errorCode && parseInt(errorCode) === ErrorCode.MISSING_ADDRESS) {
            await this.$refs.alert.showAlert({
              title: "¡Éxito!",
              text: "La dirección de tu comercio se ha actualizado correctamente. Ahora puedes regresar a la aplicación y continuar con tus operaciones de cobro de manera segura y eficiente.",
              buttons: [{ text: "Entendido" }],
            });
          } else {
              await this.$refs.alert.showAlert({
                title: "¡Éxito!",
                text: "La información ha sido actualizada con éxito.",
                buttons: [{ text: "Entendido" }],
              });
          }

          renewSession(resp);
        } else {
          if (errorMessage) this.error = errorMessage;
          else if (errors) {
            this.error = errors[0].msg;
          } else {
            this.error = resp.statusText;
          }
        }
      } else {
        if (!this.myMerchant.bankAccounts[0].bank) {
          this.$refs.alert.showAlert({
            title: "Aviso :(",
            text: "Debes seleccionar un banco valido en tu información bancaria",
            buttons: [{text: "Ok", color: "red", role: "cancel"}],
          });
        } else {
          this.$refs.alert.showAlert({
            title: "Aviso",
            text: "Te falta informacion o hay informacion invalida, revisa tus datos e intenta denuevo",
            buttons: [{text: "Ok", color: "red", role: "cancel"}],
          });
        }
      }
    },
  }
});
</script>
<style scoped>
@media (max-width: 991.98px) {
  .container {
    width: 95% !important;
  }
}

@media (min-width: 992px) {
  .container {
    width: 70% !important;
  }
}

.waiting-for-review {
  color: orange;
}

.btn {
  background-color: white !important;
  border: #f78f6e 2px solid;
  position: relative;
  right: 35px;
  top: 30px;
}
</style>
